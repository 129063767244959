import React, {Fragment, useState} from 'react';
import { Send } from '@mui/icons-material';
import axios from 'axios';
import {api_credentials} from '../vars.js'
import { FormControl,FormControlLabel, FormLabel,RadioGroup,Radio,Button } from '@mui/material';

export default  function SelectFlag() { 
    let body= {
        key: api_credentials.key,
        api_user : api_credentials.user
    }
    const [flag,setFlag] = useState('green')

    const handleUpdate = () => {
        body.flag = flag;
        axios.post(api_credentials.url+ '/set-flag',body )
        .then(res=>{
            window.alert(res.data.Data + "\n" + res.data.Message)
        })
    }
    return(
        <div style={{
            display:'flex',
            flexDirection:'column',
            width:'45%',

            alignItems:'center'
        }}>
            <FormControl style={{
   
}}>
  <FormLabel id="demo-controlled-radio-buttons-group" style={{
      color:'black',
        fontSize:20,
  }}>Change Flag</FormLabel>
  <RadioGroup
    value={flag}
    onChange={e=> {
        const {target: {value: _value}} = e;

        setFlag(_value);
    }}
  >
      <FormControlLabel value="green" defaultChecked control={<Radio />} label="Green" />
    <FormControlLabel value="black" control={<Radio  />} label="Black"  />
    <FormControlLabel value="blue" control={<Radio />} label="Blue" />
    <FormControlLabel value="race" control={<Radio />} label="Race" />
    <FormControlLabel value="red" control={<Radio />} label="Red" />
    <FormControlLabel value="yellow" control={<Radio />} label="Yellow" />
  </RadioGroup>
</FormControl>
<Button variant="contained"  endIcon={<Send />}  style={{
    width:'50%',
    marginTop:10
}}
onClick={handleUpdate}
>
        Update Flag
      </Button>
        </div >
    )
}