import { Fragment, useState } from "react";
import "./App.css";
import SelectFlag from "./Components/SelectFlag";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { api_credentials } from "./vars.js";
import { Send } from "@mui/icons-material";
import Button from "@mui/material/Button";

function App() {
  let body = {
    key: api_credentials.key,
    api_user: api_credentials.user,
  };
  const [evName, setEvName] = useState("");
  const [ticker, setTicker] = useState("");
  const [ppTeamNo, setPPTeamNo] = useState('');
  const [pp, setPP] = useState('');

  const [lapsTeamNo, setLapsTeamNo] = useState('');
  const [laps, setLaps] = useState('');
  const handleUpdate = (e) => {
    let _if = parseInt(e.nativeEvent.srcElement.id);
    body.text = _if ? ticker : evName;
    body.if = _if;
    axios.post(api_credentials.url + "/set-texts", body).then((res) => {
      window.alert(res.data.Data + "\n" + res.data.Message);
      setEvName("");
      setTicker("");
    });
  };

  const handleUpdatePP = () =>{
    body.team_nr = ppTeamNo;
    body.pp = pp;
    axios.post(api_credentials.url + "/update-pp", body).then((res) => {
      window.alert(res.data.Data + "\n" + res.data.Message);
      setPP("");
      setPPTeamNo("");
    });
  }
  const handleUpdateLaps = () =>{
    body.team_nr = lapsTeamNo;
    body.laps = laps;
    axios.post(api_credentials.url + "/update-laps", body).then((res) => {
      console.log(res.data)
      window.alert(res.data.Data + "\n" + res.data.Message);
      setLaps("");
      setLapsTeamNo("");
    });
  }
    
  return (
    <Fragment>
      <div className="main">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
            width: "90%",
            alignSelf: "center",
          }}
        >
          <SelectFlag />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              margin: "0 5px 0 5px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <TextField
                id="filled-basic"
                label="Event name"
                variant="filled"
                value={evName}
                onChange={(e) => setEvName(e.target.value)}
              />
              <Button
                id={0}
                variant="contained"
                endIcon={<Send />}
                style={{
                  width: "50%",
                  marginTop: 10,
                }}
                onClick={handleUpdate}
              >
                Update
              </Button>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            ></div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                alignSelf: "flex-end",
                justifySelf: "flex-end",
              }}
            >
              <TextField
                id="filled-basic"
                label="Ticker text"
                variant="filled"
                value={ticker}
                onChange={(e) => setTicker(e.target.value)}
              />
              <Button
                id={1}
                variant="contained"
                endIcon={<Send />}
                style={{
                  width: "50%",
                  marginTop: 10,
                }}
                onClick={handleUpdate}
              >
                Update
              </Button>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexDirection: "row",
            width: "90%",
            alignSelf: "center",
            gap:'50px',
            marginTop: "50px",
          }}
        >
          {/* pp */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
              margin: "0 5px 0 5px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <TextField
                id="filled-basic"
                label="Team Number"
                variant="filled"
                value={ppTeamNo}
                onChange={(e) => setPPTeamNo(e.target.value)}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            ></div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                alignSelf: "flex-end",
                justifySelf: "flex-end",
              }}
            >
              <TextField
                id="filled-basic"
                label="New PP value"
                variant="filled"
                value={pp}
                onChange={(e) => setPP(e.target.value)}
              />
              <Button
                id={1}
                variant="contained"
                endIcon={<Send />}
                style={{
                  width: "130%",
                  marginTop: 10,
                }}
                onClick={handleUpdatePP}
              >
                Update Penalty Points
              </Button>
            </div>
          </div>
                {/* laps */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
              margin: "0 5px 0 5px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <TextField
                id="filled-basic"
                label="Team Number"
                variant="filled"
                value={lapsTeamNo}
                onChange={(e) => setLapsTeamNo(e.target.value)}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            ></div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                alignSelf: "flex-end",
                justifySelf: "flex-end",
              }}
            >
              <TextField
                id="filled-basic"
                label="New Laps value"
                variant="filled"
                value={laps}
                onChange={(e) => setLaps(e.target.value)}
              />
              <Button
                id={1}
                variant="contained"
                endIcon={<Send />}
                style={{
                  width: "130%",
                  marginTop: 10,
                }}
                onClick={handleUpdateLaps}
              >
                Update laps
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default App;
